body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}

.block-picker {
    font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14), inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06) !important;
}

.block-picker>div:nth-child(2) {
    height: 48px !important;
}

.block-picker>div:nth-child(2)>div {
    display: none;
}

.block-picker input {
    font-size: 16px !important;
    height: 48px !important;
    padding: 0 15px !important;
}

nav a:active [aria-current="page"],
[aria-selected="true"] {
    /*colorcode for tabs*/
    background-color: #306584 !important;
    color: white !important;
    border-radius: 20px !important;
    /* padding-left : 50px !important; */
}

nav a:hover span:hover [role="tab"] {
    border-radius: 20px !important;
}

nav a:hover span [role="tab"] {
    box-sizing: none !important;
    box-shadow: none !important;
}

[role="tab"] {
    border-radius: 20px !important;
    box-shadow: none !important;
}


/* nav a [role="navigation"] {
    padding-left: 32px;
} */

.loginButton {
    border-radius: 20px !important;
    background-color: #306584 !important;
    width: 100%;
    color: white;
    height: 40px;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


/* Widget Class */

svg text,
.ptext {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Verdana;
}

.ptext.description {
    color: #2A435F;
    text-align: center
}

.ptext.title {
    color: #ffffff;
    text-align: center
}

.widgetContainer {
    text-align: center;
}

.widgetButton {
    background-color: #306584 !important;
    color: white;
    border-radius: 20px !important;
    width: 90px !important;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: x-small !important;
}

.svg-inline--fa.fa-w-14 {
    width: 1.12em;
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.flyer-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .📦grd-ara_3k6n3y {
        display: none !important;
    }

    .SideSheet {
        width: 100% !important;
    }

    .📦ovflw-y_scroll {
        overflow-y: hidden !important;
    }

    .📦ovflw-x_scroll {
        overflow-x: hidden !important;
    }

    .📦bs_kymczs {
        box-shadow: none !important;
    }

    .📦w_733px {
        width: 100% !important;
    }

    .📦grd-ara_3k6n3y {
        display: none !important;
    }
}


.textOverflow {
    display: inline;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    /* Important for long words! */
}


.fillAlert {
    background-color: rgb(227 63 41 / 33%);
}

/* @media (max-width:1440px) { */
.accountNameTextEllipsis {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: initial !important;
    white-space: nowrap;
}

/* } */
.errorMessage-text-logs {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


.dentistHearder, .membersTableTooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ql-container{
    height: 340px !important;
}
